// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contactForm.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-detail-pages-elizabeth-yount-js": () => import("./../../../src/pages/detailPages/ElizabethYount.js" /* webpackChunkName: "component---src-pages-detail-pages-elizabeth-yount-js" */),
  "component---src-pages-detail-pages-watermillstone-js": () => import("./../../../src/pages/detailPages/Watermillstone.js" /* webpackChunkName: "component---src-pages-detail-pages-watermillstone-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-ui-image-image-group-js": () => import("./../../../src/pages/ui/Image/ImageGroup.js" /* webpackChunkName: "component---src-pages-ui-image-image-group-js" */),
  "component---src-pages-ui-image-image-js": () => import("./../../../src/pages/ui/Image/Image.js" /* webpackChunkName: "component---src-pages-ui-image-image-js" */)
}

